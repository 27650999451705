<template>
  <overlay
    :show="loading"
  >
    <div class="content-wrapper">
      <page-header
        :screen-name="pageTitle"
        :link-items="linkItems"
      />
      <div class="card p-2">
        <b-row>
          <b-col
            sm="6"
            lg="4"
          >
            <div class="mb-4">
              <h5>Nome completo*</h5>
              <p>{{ chooseCompanyUser.nome }}</p>
            </div>

            <div class="mb-4">
              <h5>CPF*</h5>

              <p>{{ chooseCompanyUser.cpf | VMask('###.###.###-##') }}</p>
            </div>

            <div class="mb-4">
              <h5>Perfil*</h5>
              <p
                v-for="(profile, index) in chooseCompanyUser.perfil"
                :key="index"
              >{{ profile.descricao }}</p>
            </div>
          </b-col>

          <b-col
            sm="6"
            lg="4"
          >
            <div class="mb-4">
              <h5>E-mail*</h5>
              <p>{{ chooseCompanyUser.email }}</p>
            </div>

            <div class="mb-4">
              <h5>Sexo*</h5>
              <p>{{ chooseCompanyUser.sexo }}</p>
            </div>

            <div class="mb-4">
              <h5>Empresas*</h5>
              <span
                v-for="(company, index) in chooseCompanyUser.empresa"
                :key="index"
              >

                {{ company.nome_empresa }}
                <br>
              </span>
            </div>

          </b-col>

          <b-col
            sm="6"
            lg="4"
          >
            <div class="mb-4">
              <h5>Data de nascimento*</h5>
              <p>{{ chooseCompanyUser.data_nascimento }}</p>
            </div>

            <div class="mb-4">
              <h5>Grupos</h5>
              <app-collapse class="collapse-groups" v-if="chooseCompanyUser.grupos.length > 0">
                <app-collapse-item
                  v-for="(grupo, index) in chooseCompanyUser.grupos"
                  :key="index"
                  :title="grupo.descricao"
                >
                  <ul v-if="empresasAgrupadasByGrupo[grupo.id_grupo]">
                    <li
                      v-for="empresa in empresasAgrupadasByGrupo[grupo.id_grupo]"
                      :key="empresa.id_empresa"
                    >
                      {{ empresa.nome_empresa }}
                    </li>
                  </ul>
                  <p v-else>Não há empresas neste grupo.</p>
                </app-collapse-item>
              </app-collapse>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <button
              type="button"
              class="btn custom-blue"
              @click="redirectUpdatePage"
            >
              <feather-icon
                icon="EditIcon"
              />
              Editar
            </button>
          </b-col>
        </b-row>
      </div>
    </div>
  </overlay>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import moment from 'moment'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import Overlay from '@/views/components/custom/overlay/Overlay.vue'
import { getCompanyUserId } from '@/views/custom-pages/gerenciarUsuarios/requests'
import { warningMessage } from '@/libs/sweetalerts'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';

export default {
  components: {
    BRow,
    BCol,
    Overlay,
    PageHeader,
    AppCollapse, AppCollapseItem
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Gerenciar usuários',
          active: true,
          routeName: 'usuario-empresa-list',
        },
        {
          name: '...',
          active: true,
        },
      ],

      moment,

      pageTitle: '...',

      loading: true,

      chooseCompanyUser: {
        id_usuario: '-',
        nome: '-',
        email: '-',
        cpf: '-',
        ativo: '-',
        data_nascimento: '-',
        sexo: '-',
        perfil: [],
        empresa: [],
        grupos: [],
      },
      empresasAgrupadasByGrupo: []
    }
  },

  computed: {
    getItemInStore() {
      return this.$store.getters['companyUserState/getCompanyUserView']
    },
  },

  created() {
    if (!this.getItemInStore) {
      this.redirectToMainPage()
    }

    this.handleGetCompanyUserId()
  },

  methods: {
    async handleGetCompanyUserId() {
      this.loading = true

      await getCompanyUserId(this.getItemInStore.id_usuario)
        .then(response => {
          const {
            id_usuario,
            nome,
            email,
            cpf,
            ativo,
            data_nascimento,
            sexo,
            empresa,
            perfil,
            grupos
          } = response.data

          this.chooseCompanyUser = {
            id_usuario,
            nome,
            email,
            cpf,
            ativo,
            data_nascimento: moment(data_nascimento).format('DD/MM/YYYY'),
            sexo,
            empresa,
            perfil,
            grupos
          }

          this.agruparEmpresasGrupo();

          this.pageTitle = nome
          this.linkItems[1].name = nome
        })
        .catch(() => {
          warningMessage('Não foi possível realizar a sua solicitação.<br /> Entre em contato com o suporte.')
        })

      this.loading = false
    },

    agruparEmpresasGrupo() {
      if (this.chooseCompanyUser.empresa.length > 0) {
        this.empresasAgrupadasByGrupo = this.chooseCompanyUser.empresa.reduce((acc, cur) => {
          if (cur.id_grupo !== null) {
            if (!acc[cur.id_grupo]) {
              acc[cur.id_grupo] = [];
            }
            acc[cur.id_grupo].push(cur);
          }
          return acc;
        }, {});
      }
    },

    redirectToMainPage() {
      this.$store.commit('companyUserState/setCompanyUserView', null)
      this.$router.replace({ name: 'usuario-empresa-list' })

      return false
    },

    redirectUpdatePage() {
      this.$store.commit('companyUserState/setCompanyUserUpdate', this.chooseCompanyUser)

      this.$router.replace({ name: 'usuario-empresa-edit' })
    },
  },

}
</script>
<style>
  .collapse-groups .card .card-header{
    padding: 0;
  }
  .collapse-groups .card .card-header .collapse-title{
    font-weight: normal;
  }
  .collapse-groups .card .card-body ul{
    padding: 0 20px;
  }
</style>